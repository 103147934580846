



const Hst = () => import('../main/pages/Hst.vue');


const Select = () => import('../main/pages/Select.vue');

const Redirect = () => import('../main/pages/Redirect.vue');
const Linie = () => import('../main/pages/Linie.vue');
const Map = () => import('../main/pages/Map.vue');


export const routes = [
    {
        path: '/redir/:url',
        name: 'redirect',
        component: Redirect,
        props: (route) => ({url: route.params.url,fetch:route.query.m})
    },

    {path: '/map/:id', name: 'map', component: Map, props: (route) => ({id: route.params.id})},

    {
        path: '/linie/:id',
        name: 'linie',
        component: Linie,
        props: (route) => ({id: route.params.id})
    },
    {
        path: '/',
        name: 'home',
        component: Hst
    },
    {
        path: '/hst',
        name: 'hst',
        component: Hst
    },
    {
        path: '/select',
        name: 'select',
        component: Select,
        props: (route) => ({type: route.query.type,ns:route.query.ns})
    },
];
